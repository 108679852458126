import SignInSide from "../../components/SignInSide";
import { useSelector } from "react-redux";
import Dashboard from "../../components/Dashboard";
import React from "react";

class WebpageViewer extends React.Component {
  render() {
    return (
      <div>
        <iframe
          src="https://temptemp3.github.io/canva-carthage/"
          title="Webpage Viewer"
          width="100%"
          height="1200px"
          frameBorder="0"
          scrolling="auto"
        ></iframe>
      </div>
    );
  }
}


const Page = () => {
  return <WebpageViewer />;
};

export default Page;
