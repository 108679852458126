import SignInSide from "../../components/SignInSide";
import { useSelector } from "react-redux";
import Sponsor from "../../components/Sponsor";
import ActivityList from "../../components/ActivityList";

import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Table, TableBody, TableHead, Typography } from "@mui/material";

function TabPage() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Overview" />
        <Tab label="Projects" />
        <Tab label="Contacts" />
        <Tab label="Leads" />
        <Tab label="Resources" />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        {/* Content for Overview tab */}
        <Typography variant="h4">Activities</Typography>
        <ActivityList />
        <div>
          <textarea></textarea>
        </div>
        <button>Comment</button>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        {/* Content for Projects tab */}
        <Sponsor />
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        {/* Content for Contacts tab */}
        <Table>
          <TableHead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone Number</th>
              <th>Email</th>
            </tr>
          </TableHead>
          <TableBody>
            <tr>
              <td>John</td>
              <td>Doe</td>
              <td>(123) 456-7890</td>
              <td>john.doe@example.com</td>
            </tr>
            <tr>
              <td>Jane</td>
              <td>Smith</td>
              <td>(987) 654-3210</td>
              <td>jane.smith@example.com</td>
            </tr>
            <tr>
              <td>Michael</td>
              <td>Johnson</td>
              <td>(555) 123-4567</td>
              <td>michael.j@example.com</td>
            </tr>
            <tr>
              <td>Susan</td>
              <td>Williams</td>
              <td>(111) 222-3333</td>
              <td>susan.w@example.com</td>
            </tr>
          </TableBody>
        </Table>
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <Table>
          <TableHead>
            <tr>
              <th>Project</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone Number</th>
              <th>Email</th>
            </tr>
          </TableHead>
          <TableBody>
            <tr>
              <td>Private Investment Fund</td>
              <td>John</td>
              <td>Doe</td>
              <td>(123) 456-7890</td>
              <td>john.doe@example.com</td>
            </tr>
            <tr>
              <td>Mix - Use Offering</td>
              <td>Michael</td>
              <td>Johnson</td>
              <td>(555) 123-4567</td>
              <td>michael.j@example.com</td>
            </tr>
          </TableBody>
        </Table>
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <ul style={{ listStyle: "none" }}>
          <li>
            <button>Upload photos</button>
          </li>
          <li>
            <button>Upload videos</button>
          </li>
          <li>
            <button>Upload documents</button>
          </li>
        </ul>
      </TabPanel>
    </>
  );
}

function TabPanel({ value, index, children }) {
  return (
    <div hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const Page = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  return isLoggedIn ? <TabPage /> : <SignInSide />;
};

export default Page;
