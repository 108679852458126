import React, { useState } from "react";
import {
  AppBar,
  Button,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Drawer,
  Avatar,
  Box,
  Chip,
  Divider,
} from "@mui/material";

import DashboardIcon from "@mui/icons-material/Dashboard";
import StoreIcon from "@mui/icons-material/Store";
import DescriptionIcon from "@mui/icons-material/Description";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { makeStyles } from "@mui/styles";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Replace the 'logo.png' with your logo file path
import logo from "../../assets/logo.svg";

import { useDispatch, useSelector } from "react-redux";

import { useLocation, Link, useNavigate } from "react-router-dom";

import { logout } from "../../actions/authActions";

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import withProfile from "../../hocs/withProfile";

const drawerWidth = 300; // Set the desired width for the drawer

const useStyles = makeStyles((theme) => ({
  appBar: {
    minHeight: "2.75rem",
    background: "#d3c7a2 !important",
  },
  logo: {
    maxWidth: "2.75rem",
    marginLeft: "auto",
    marginRight: "auto",
    filter: "grayscale(1) brightness(3)",
  },
  drawerLogo: {
    maxWidth: "2.75rem",
    marginLeft: "auto",
    marginRight: "auto",
    filter: "grayscale(1) brightness(0)",
  },
  brandName: {
    fontWeight: "900 !important",
    color: "white",
  },
  iconButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: "white",
    fontWeight: 900,
    marginRight: theme.spacing(1) + " !important",
    textAlign: "left",
  },
  menuButton: {
    marginRight: theme.spacing(1),
    textTransform: "none !important", // Preserve the text case
    height: "4rem",
  },
  activeMenuItem: {
    fontWeight: "900 !important",
    textDecoration: "none",
    position: "relative", // To create the stacking context for pseudo-element
  },
  avatarContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "right",
  },
  drawer: {
    width: drawerWidth,
  },
  avatar: {
    marginLeft: theme.spacing(2),
    marginRight: 0,
    pointer: "cursor",
  },
  avatarMenu: {
    marginTop: theme.spacing(2),
  },
  avatarListItemText: {
    paddingLeft: theme.spacing(2), // Add padding to create space between the avatar and text
  },
}));

const menuItems = [
  { label: "Dashboard", icon: <DashboardIcon />, path: "/a/" },
  { label: "Marketplace", icon: <StoreIcon />, path: "/a/marketplace" },
  { label: "Documents", icon: <DescriptionIcon />, path: "/a/documents" },
  {
    label: "Resources",
    icon: <MonetizationOnIcon />,
    path: "/a/transactions",
  },
  {
    label: "Sign Out",
    icon: <LogoutIcon />,
    path: "/",
    drawerOnly: true,
  },
];

const CustomAppBar = ({ data }) => {
  console.log({ data });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const user = useSelector((state) => state.user);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null); // State to anchor the menu to the avatar
  const [isAvatarMenuOpen, setIsAvatarMenuOpen] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(
    !isSmallScreen && !isAvatarMenuOpen
  );

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsAvatarMenuOpen(!isAvatarMenuOpen);
  };

  const handleAvatarMenuClose = () => {
    setAnchorEl(null);
    setIsAvatarMenuOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    const {
      pool: { clientId: ClientId, userPoolId: UserPoolId },
    } = user;
    const poolData = {
      UserPoolId, // Your user pool id here
      ClientId, // Your client id here
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.signOut(() => {
      dispatch(logout());
      handleAvatarMenuClose();
      navigate("/");
    });
  };

  const profileMenuItem = (
    <MenuItem
      onClick={() => {
        navigate("/a/settings/profile");
        handleAvatarMenuClose();
      }}
    >
      <ListItemIcon>
        <Avatar alt={data?.name || "No Name"} src="/path/to/avatar-image.jpg" />
      </ListItemIcon>
      <ListItemText
        primary={data?.name || "No Name"}
        secondary={
          <>
            {data?.email || "No Email"}
            <Chip
              label="Investor"
              size="small"
              color="primary"
              className={classes.chip}
              sx={{ ml: 1 }}
            />
          </>
        }
        classes={{ root: classes.avatarListItemText }}
      />
    </MenuItem>
  );

  const appBarContent = (
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        className={classes.iconButton}
        aria-label="menu"
      >
        {<img src="/assets/logo.png" alt="Logo" className={classes.logo} />}
      </IconButton>
      {/* Title */}
      <Typography variant="h6" className={classes.title}>
        {process.env.REACT_APP_TITLE}
      </Typography>
      {/* Menu items shown on larger screens */}
      <Hidden smDown>
        {/* Show only when isLoggedIn is true */}
        {isLoggedIn && (
          <>
            {false && menuItems.map(
              (item, index) =>
                !item.drawerOnly && (
                  <Button
                    key={index}
                    color="inherit"
                    className={`${classes.menuButton} ${
                      location.pathname === item.path
                        ? classes.activeMenuItem
                        : ""
                    }`}
                    component={Link}
                    to={item.path}
                  >
                    {item.label}
                  </Button>
                )
            )}
          </>
        )}
      </Hidden>
      <Box className={classes.avatarContainer}>
        {/* Show only when isLoggedIn is true */}
        {isLoggedIn && (
          <Avatar
            alt={data?.name || "No Name"}
            src="/path/to/avatar-image.jpg"
            className={classes.avatar}
            onClick={isSmallScreen ? handleDrawerOpen : handleAvatarClick}
          />
        )}
        {/* The onClick event opens the menu when the avatar is clicked */}
        {!isSmallScreen && isAvatarMenuOpen && (
          <Menu
            anchorEl={anchorEl}
            open={isAvatarMenuOpen}
            onClose={handleAvatarMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            classes={{ paper: classes.avatarMenu }}
          >
            {/* Avatar, Name, Email, and Chip (Dropdown for Larger Screens) */}
            {/*<MenuItem
              onClick={() => {
                navigate("/settings/profile");
                handleAvatarMenuClose();
              }}
            >
              <ListItemIcon>
                <Avatar alt="User Avatar" src="/path/to/avatar-image.jpg" />
              </ListItemIcon>
              <ListItemText
                primary="John Doe" // Replace with the user's name
                secondary={
                  <>
                    john.doe@example.com
                    <Chip
                      label="Investor"
                      size="small"
                      color="primary"
                      className={classes.chip}
                    />
                  </>
                }
                classes={{ root: classes.avatarListItemText }}
              />
              </MenuItem>*/}
            {profileMenuItem}
            <Divider />
            {/*<MenuItem
              onClick={() => {
                navigate("/settings");
                handleAvatarMenuClose();
              }}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>*/}
            <MenuItem onClick={handleSignout}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              Sign Out
            </MenuItem>
          </Menu>
        )}
      </Box>
    </Toolbar>
  );
  const drawerContent = (
    <List>
      {/* Icon and brand name */}
      <ListItem button component={Link} to="/">
        <IconButton
          edge="start"
          color="inherit"
          className={classes.iconButton}
          aria-label="menu"
        >
          {<img src={logo} alt="Logo" className={classes.drawerLogo} />}
        </IconButton>
        <ListItemText primary={process.env.REACT_APP_TITLE} />
      </ListItem>
      <Divider />
      {/* Avatar, Name, Email, and Chip */}
      <ListItem>
        <ListItemIcon>
          <Avatar alt="User Avatar" src="/path/to/avatar-image.jpg" />
        </ListItemIcon>
        <ListItemText
          primary={data?.name || "No Name"}
          secondary={
            <>
              {data?.email || "No Email"}
              <br />
              <Chip
                label="Investor"
                size="small"
                color="primary"
                className={classes.chip}
              />
            </>
          }
        />
      </ListItem>
      <Divider />
      {menuItems.map((item, index) => (
        <ListItem
          button
          key={index}
          onClick={handleDrawerClose}
          component={Link}
          to={item.path}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primary={item.label}
            className={
              location.pathname === item.path ? classes.activeMenuItem : ""
            }
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <AppBar color="inherit" position="static" className={classes.appBar}>
      {appBarContent}
      <Hidden mdUp>
        <Drawer
          anchor="left"
          open={openDrawer}
          onClose={handleDrawerClose}
          classes={{ paper: classes.drawer }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

export default withProfile(CustomAppBar);
