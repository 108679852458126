import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const activities = [
  { title: 'Meeting with clients', datetime: '2023-11-07T10:30:00' },
  { title: 'Lunch break', datetime: '2023-11-07T12:00:00' },
  { title: 'Project presentation', datetime: '2023-11-07T14:00:00' },
  // Add more activities with datetime as needed
];

function ActivityList() {
  return (
    <List>
      {activities.map((activity, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={activity.title}
            secondary={new Date(activity.datetime).toLocaleString()}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

export default ActivityList;
