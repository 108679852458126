import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { useWallet } from "@txnlab/use-wallet";
import ARC200Service from "../../services/ARC200Service";
import { makeStdLib } from "../../utils/reach";
import withBalance from "../../hocs/withASABalance";
import withTokenMetadata from "../../hocs/withARC200TokenMetadata";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontWeight: 900,
    fontSize: "0.875rem",
    marginRight: theme.spacing(2),
  },
  balance: {
    fontSize: "1.25rem",
    textAlign: "left",
  },
  loadingBalance: {
    flexGrow: 1,
  },
  paperContainer: {
    padding: theme.spacing(2),
    backgroundColor: "grey",
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    flexGrow: 1,
    display: "flex", // Added to make it a flex container
    alignItems: "center", // Vertically center the content
  },
  dollarSign: {
    marginRight: theme.spacing(1), // Add spacing between $ sign and balance
  },
  button: {
    color: "black !important",
  },
}));

const Bridge = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h6">Fund Account</Typography>
        <Typography variant="body2">
          Deposit USDC into your account from wallet or by wiring funds to the
          account below.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Wiring Instructions</Typography>
        <Typography variant="body2">
          To wire funds to your account, send USD to the following account:
        </Typography>
        <Stack direction="column">
          <Typography variant="body2">Account Number: 1030000000001</Typography>
          <Typography variant="body2">Routing Number: 1031000024</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Bridge;
