import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

function StackedLinearProgressBar() {
  return (
    <Box>
      <LinearProgress
        variant="determinate"
        value={50} // Set the first color's progress percentage (e.g., 50%)
        sx={{ backgroundColor: 'lightgray' }} // Background color
      />
      <LinearProgress
        variant="determinate"
        value={30} // Set the second color's progress percentage (e.g., 30%)
        sx={{ backgroundColor: 'transparent' }} // Transparent background
      />
    </Box>
  );
}

export default StackedLinearProgressBar;
