import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import USDCIcon from "../../assets/usdc.svg";
import { Button, InputAdornment, Skeleton, Tooltip } from "@mui/material";
import { useWallet } from "@txnlab/use-wallet";
import ARC200Service from "../../services/ARC200Service";
import { makeStdLib } from "../../utils/reach";
import Title from "./Title";
import withBalance from "../../hocs/withASABalance";
import withTokenMetadata from "../../hocs/withARC200TokenMetadata";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Wire from "./Wire";

const stdlib = makeStdLib();

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontWeight: 900,
    fontSize: "0.875rem",
    marginRight: theme.spacing(2),
  },
  balance: {
    fontSize: "1.25rem",
    textAlign: "left",
  },
  loadingBalance: {
    flexGrow: 1,
  },
  paperContainer: {
    padding: theme.spacing(2),
    backgroundColor: "grey",
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    flexGrow: 1,
    display: "flex", // Added to make it a flex container
    alignItems: "center", // Vertically center the content
  },
  dollarSign: {
    marginRight: theme.spacing(1), // Add spacing between $ sign and balance
  },
  button: {
    color: "black !important",
  },
}));

const Bridge = ({ appId, backendId, token, balance }) => {
  const classes = useStyles();
  const { activeAccount } = useWallet();
  console.log({ appId, backendId, token, balance });
  const handleDeposit = async () => {
    const amt = Number(window.prompt("Enter amount to deposit"));
    const ret = await ARC200Service.deposit(
      token,
      activeAccount?.address,
      `${amt}`,
      backendId
    );
    console.log({ ret });
  };
  const handleWithdraw = async () => {
    const amt = Number(window.prompt("Enter amount to deposit"));
    await ARC200Service.approve(
      token,
      activeAccount.address,
      token.appAddress,
      "10.0",
      backendId
    );
    await ARC200Service.withdraw(
      token,
      activeAccount?.address,
      `${amt}`,
      backendId
    );
  };
  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={6}>
        <Paper>
          <Button
            fullWidth
            size="large"
            variant="text"
            className={classes.button}
            onClick={handleWithdraw}
          >
            Withdraw
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <Button
            fullWidth
            size="large"
            variant="text"
            className={classes.button}
            onClick={handleDeposit}
          >
            Deposit
            <Tooltip title={<Wire />}>
              <InfoOutlinedIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Tooltip>
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Typography color="text.secondary" sx={{ flex: 1 }}>
          Available: {balance} {token?.symbol}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withBalance(withTokenMetadata(Bridge));
