import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Skeleton,
  Stack,
  Modal,
  TextareaAutosize,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";

import * as icoBackend from "../../backend/ico/index.ico.mjs";
import { useWallet } from "@txnlab/use-wallet";
import { makeStdLib } from "../../utils/reach";
import { zeroAddress } from "../../utils/algorand";
import { fromSome } from "../../utils/common";

import LinearProgress from "../LinearProgressWithLabel";
import StackedLinearProgressBar from "../StackedLinearProgressBar";

const stdlib = makeStdLib();
const pc = stdlib.parseCurrency;
const bn = stdlib.bigNumberify;
const fc = stdlib.formatCurrency;

const icoCtcInfo = 266874037;

const InvestmentForm = ({ onSubmit }) => {
  const [state, setState] = useState();
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [commitment, setCommitment] = useState();
  const { activeAccount } = useWallet();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!activeAccount) return;
    if (commitment) return;
    (async () => {
      const acc = await stdlib.connectAccount({ addr: zeroAddress });
      const ctc = acc.contract(icoBackend, icoCtcInfo);
      const commitment = fc(
        fromSome(await ctc.v.commitment(activeAccount.address), bn(0))
      );
      setCommitment(commitment);
    })();
  }, [activeAccount]);

  useEffect(() => {
    if (state) return;
    (async () => {
      const acc = await stdlib.connectAccount({ addr: zeroAddress });
      const ctc = acc.contract(icoBackend, icoCtcInfo);
      const state = fromSome(await ctc.v.state(), {});
      setState(state);
    })();
  }, [state]);

  console.log({ state });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const parsedAmount = parseFloat(investmentAmount);
    if (isNaN(parsedAmount)) {
      alert("Please enter a valid investment amount.");
    } else {
      //onSubmit(parsedAmount);
      const acc = await stdlib.connectAccount({ addr: activeAccount.address });
      const ctc = acc.contract(icoBackend, icoCtcInfo);
      await ctc.a.Trader.makeCommitment(pc(parsedAmount));
      setState(null);
      setCommitment(parsedAmount);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 800,
    bgcolor: "background.paper",
    border: "2px solid white",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Stack gap={1}>
      <Typography variant="body2">
        <strong>Total Commitment:</strong>
      </Typography>
      <LinearProgress
        value={(Number(fc(state?.totalCommitment || bn(0))) / 32_000_000) * 100}
        label={
          !state ? (
            <Skeleton sx={{ display: "inline" }} variant="text" width={10} />
          ) : (
            //`$${Number(fc(state?.totalCommitment || bn(0))).toLocaleString()}`
            `$${Number(5_000_000).toLocaleString()}`
          )
        }
      />
      <Typography variant="body2">
        <strong>Total Interested:</strong>
      </Typography>
      <LinearProgress
        color="secondary"
        value={(Number(fc(state?.totalCommitment || bn(0))) / 32_000_000) * 100 * 8}
        label={
          !state ? (
            <Skeleton sx={{ display: "inline" }} variant="text" width={10} />
          ) : (
            //`$${Number(fc(state?.totalCommitment || bn(0))).toLocaleString()}`
            `$${Number(5_000_000).toLocaleString()}`
          )
        }
      />
      <Typography variant="body2">
        <strong>Your Commitment:</strong> $
        {!commitment || !state ? (
          <Skeleton sx={{ display: "inline" }} variant="text" width={10} />
        ) : (
          `${Number(commitment).toLocaleString()} (${Number(
            (Number(commitment) / Number(fc(state?.totalCommitment))) * 100
          ).toFixed(2)}%)`
        )}
      </Typography>
      <form onSubmit={handleSubmit}>
        {/*<Box mt={2}>
          <TextField
            size="small"
            id="investmentAmount"
            label="Enter Investment Amount"
            variant="outlined"
            type="number"
            fullWidth
            value={investmentAmount}
            onChange={(e) => setInvestmentAmount(e.target.value)}
            inputProps={{
              min: 25,
              step: 25,
            }}
          />
          </Box>*/}
        <Box mt={2}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            Invest Now
          </Button>
        </Box>
      </form>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h6" sx={{ color: "royalblue" }}>
              Private Investment Fund
            </Typography>
            <Stack direction="row" spacing={2}>
              <video
                src="/assets/offers/offer-001.mp4"
                width="50%"
                autoPlay
                loop
              />
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexGrow: 1,
                }}
              >
                <Box>
                  <Typography variant="body2">
                    <strong>Annual Return:</strong> 15.1%
                  </Typography>
                  <Typography variant="body2">
                    <strong>Cash on Cash:</strong> 6.7%
                  </Typography>
                  <Typography variant="body2">
                    <strong>Equity Multiple:</strong> 1.9x
                  </Typography>
                  <Typography variant="body2">
                    <strong>Project Value:</strong> 5M
                  </Typography>
                  <Typography variant="body2">
                    <strong>Min. Investment:</strong> $25k
                  </Typography>
                </Box>
                <TextField
                  size="large"
                  id="investamt"
                  label="Enter investment offer amount"
                  fullWidth
                />
              </Stack>
            </Stack>
            <Typography variant="body">Disclosures & Disclaimers</Typography>
            <textarea
              fullWidth
              readOnly
              rows={10}
              style={{
                resize: "none",
                border: 0,
                padding: "5px",
                backgroundColor: "#E5E4E2",
              }}
            >
              This overview does not constitute an offer to sell you a security,
              or a solicitation of an offer by you to purchase a security. No
              offer to sell or solicitation of an offer to buy may be made prior
              to the delivery by the project sponsor of definitive documentation
              relating to a proposed investment (collectively, the "Offering
              Materials"), including, in most cases, (1) an offering circular or
              private placement memorandum describing the investment opportunity
              and the rights, preferences and obligations attached to the
              security being offered, (2) an operating or similar agreement
              governing operation of the business entity being formed and
              defining the rights of equity owners, and (3) a form of
              subscription agreement governing your formal offer to subscribe
              for an equity interest in a Carthage Construction project entity.
              You are urged to review carefully all offering materials that are
              provided to you by the project sponsor before making any
              investment decision. You are also advised to consult with your own
              tax, legal, financial and other advisers prior to making an
              investment. The overview is also summary in nature, and does not
              purport to be complete. All offers and sales that are made through
              the Carthage.sm8i.com portal have not been registered under the
              United States Securities Act of 1933, as amended, or registered or
              qualified under applicable state securities laws. Neither the
              Securities and Exchange Commission nor any state regulatory
              authority has reviewed, approved or disapproved of any offers or
              sales, the related offering materials, or the terms of any
              offering, or determined whether the offering materials are
              truthful or complete. It is illegal for any person to tell you
              otherwise. Indications of Interest No money or binding legal
              commitment is being requested at this time. Offers and sales will
              only be made pursuant to the formal Offering Materials to be
              provided by the project sponsor. Investor commitments to purchase
              can only be made pursuant to reviewing, signing and submitting a
              subscription agreement, operating agreement, and private placement
              memorandum provided by the project sponsor. The project sponsor
              may accept or reject a prospective investor after review of the
              signed project documents in its sole and absolute discretion.
              Confidentiality The project sponsor is a private company that
              operates in a highly competitive industry. Accordingly, the
              project sponsor takes great precautions to protect its proprietary
              information and confidential documents concerning its business
              operations, financial information and prospective operations and
              in this regard maintains an unbending corporate policy to protect
              its confidential information from being disseminated. Therefore,
              any and all information provided by the project sponsor is
              strictly confidential, solely for the purpose of evaluating a
              potential purchase of units in a specific project and for no other
              purposes. With the sole exceptions of your officers, directors,
              financial advisors, accountants or legal counsel as absolutely
              necessary to evaluate the possible transaction contemplated
              herein, the confidential information shall not be disclosed to any
              third party without the project sponsor’s written consent. By
              accepting receipt of this information, you acknowledge that the
              information provided to you shall be held in the strictest
              confidence, shall not be copied or disseminated in any manner
              except to the individuals mentioned above and shall be only used
              for the purpose for which it is intended. Electronic Consent I
              understand and acknowledge that by submitting this offer, I
              authorize the sponsor to make, and consent to the sponsor making,
              my account documents, including without limitation, private
              placement memoranda, offering circulars, subscription agreements
              and other offering-related documents; annual reports; tax
              documents the sponsor is required to provide by the Internal
              Revenue Service, including Form 1099 and Schedule K-1 (the “Tax
              Documents”); account statements; acquisition updates; and other
              investor communications (collectively, the “Documents”) available
              to me electronically. By consenting to electronically receive the
              Documents, including the Tax Documents, I authorize the sponsor to
              make the Documents (including the Tax Documents) available to me
              through my Investor Room and via the “Documents” list which is
              accessible from the main navigational menu. I acknowledge and
              understand that I may elect to receive paper copies of the
              Documents (including the Tax Documents) by mail at any time by
              changing my delivery preferences under “Electronic Consent” in my
              Investor Portal or by contacting the sponsor directly. I agree to
              inform the sponsor of any changes to my email address or mailing
              address.
            </textarea>
            <Box>
              <Typography variant="body">Acknowledgements</Typography>
              <br />
              {[
                "I acknowledge that I am providing the project sponsor an indication of my interest to invest in this offering based on my review of the materials made available to me.",
                "I have read the foregoing explanation and disclaimer and understand its contents, including that I will be asked to review, complete, execute and deliver additional materials, including a subscription agreement, operating agreement, and private placement memorandum before my commitment to invest will be complete, and no offer will be binding until it is accepted in its sole and absolute discretion by the project sponsor.",
                //"I represent and warrant on behalf of the Investing Entity that each direct and indirect beneficial owner of 25% or more of the equity interest in the Investing Entity is listed as an 'Investor' above. [Note: If you have questions about the Investing Entity setup, please reach out to ir@crowdstreet.com",
                "I understand that the funds due date may change, either at the discretion of the sponsor, or upon the offering becoming oversubscribed.",
              ].map((el) => (
                <>
                  <input
                    type="checkbox"
                    id="item1"
                    name="item1"
                    value="item1"
                  />
                  <label for="item1">{el}</label>
                  <br />
                  <br />
                  {/*<FormControlLabel
                      sx={{ fontSize: "10px" }}
                      control={
                        <Checkbox
                        //checked={checkedItems.item1}
                        //onChange={handleCheckboxChange("item1")}
                        />
                      }
                      label={el}
                    />
                    <Divider />
                    */}
                </>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    alert("Offer submitted!");
                  }, 2000);
                }}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default InvestmentForm;
